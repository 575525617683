@import '../../utils';

.SearchBar {
    position: relative;
}

.Input {
    padding: 1.5rem 4rem;
    font-size: 1.5rem;
    border-radius: 100px;
    width: 100%;
    border: 1px solid $light-border;

    &:focus {
        outline: none;
        border-color: $primary;
    }
}

.InputWhenSearching {
    border-radius: 25px 25px 0 0;
}

.Results {
    background-color: $offset;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 0 2px 1px rgba($text, 0.15);
    z-index: 15;
    border: 1px solid $bg;
}

.Spinner {
    padding: 1rem 0;
}
