@import '../../utils';

.Sidebar {
    position: fixed;
    width: 20rem;

    @media only screen and (max-width: 850px) {
        padding: 8rem 2rem 4rem 2rem;
        background-color: $offset;
        box-shadow: 2px 0 4px rgba($text, 0.15);
        z-index: 20;
        top: 0;
        left: 0;
        height: 100%;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        overflow-y: auto;
        box-sizing: content-box;
    }

    @media only screen and (max-width: 400px) {
        width: 100%;
        box-sizing: border-box;
    }
}

.Toggle {
    display: none;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    background-color: $primary;
    position: fixed;
    top: 2rem;
    left: 2rem;
    z-index: 30;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2rem 1px rgba($text, 0.2);
    cursor: pointer;

    @media only screen and (max-width: 850px) {
        display: flex;
    }
}

.ToggleIcon,
.ToggleIcon::before,
.ToggleIcon::after {
    display: inline-block;
    width: 2.5rem;
    height: 2px;
    background-color: $offset;
    border-radius: 1rem;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
        top 0.3s ease-in-out;
}

.ToggleIcon {
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        top: -0.8rem;
    }
    &::after {
        top: 0.8rem;
    }
}

.ToggleIconWhenVisible {
    background-color: transparent;

    &::before {
        transform: rotate(135deg);
        top: 0;
    }
    &::after {
        top: 0;
        transform: rotate(-135deg);
    }
}

.SidebarMobileVisible {
    transform: translateX(0);
}

.Nav {
    height: 100%;
}

.Logo {
    font-size: 2.8rem;
    padding-left: 2rem;
    margin-bottom: 10rem;

    @media only screen and (max-width: 576px) {
        margin-bottom: 6rem;
    }
    @media only screen and (max-width: 400px) {
        margin-bottom: 4rem;
    }
}

.LogoLink {
    color: $primary;
    text-decoration: none;

    &:focus {
        outline: 2px solid $primary;
        outline-offset: 5px;
    }
}

.Title {
    color: $light-text;
    padding: 0 2rem;
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.ListItem {
    list-style: none;
    display: flex;
    margin: 1rem 0;
}

.Link {
    display: inline-flex;
    align-items: center;
    width: 100%;
    color: $text;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    border: 2px solid transparent;
    border-radius: 100px;
    transition: border-color 0.15s ease-in-out;

    &:focus {
        outline: none;
        border-color: $secondary;
    }
}

.LinkIcon {
    fill: $text;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 1rem;
}

.ActiveLink {
    background-color: $secondary;
    color: $offset;

    .LinkIcon {
        fill: $offset;
    }
}
