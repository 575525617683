@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import-normalize;
@import './utils';

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;

    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }
    ::-webkit-scrollbar-track {
        background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
        background: $primary;
    }
}

body {
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $text;
    overflow-y: scroll;
}

h1 {
    margin: 0;
}

.App {
    display: grid;
    grid-template-columns: 20rem 1fr;
    grid-column-gap: 4rem;
    padding: 4rem 2rem;

    @media only screen and (max-width: 850px) {
        grid-column-gap: 0;
        grid-template-columns: 1fr;
    }
}

.main-page {
    grid-column-start: 2;
    padding-bottom: 10rem;

    @media only screen and (max-width: 850px) {
        grid-column-start: 1;
    }
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1600px) {
    .container {
        max-width: 1600px;
    }
}

::-moz-focus-inner {
    border: 0;
}
