@import '../../utils';

.Item {
    list-style: none;
}

.Link {
    padding: 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: $text;
    transition: background-color 0.15s ease-in-out;

    &:focus,
    &:hover {
        outline: 0;
        background-color: $bg;
    }
}

.Image {
    width: 4rem;
    height: 4rem;
    margin-right: 1.5rem;
    flex-shrink: 0;
}

.Title {
    color: $text;
    font-size: 1.5rem;
}

.Publisher {
    color: $light-text;
    font-size: 1.4rem;
}
